import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Data, Router } from '@angular/router';
import { CompanyService } from './company.service';
import { map } from 'rxjs';

@Injectable()
export class LatestCompanyResolverService {

    constructor(private companyService: CompanyService, private router: Router) {}

    resolve(route: ActivatedRouteSnapshot,) {
        return this.companyService.getLatestCompany().pipe(
            map((res: string) => {
                if(!res) {
                    this.router.navigate(['register']);
                    return null;
                }
                return res;
            })
        );
    }
}
