import { HttpClient } from "@angular/common/http";
import { Injectable, signal } from "@angular/core";
import { Data } from "@angular/router";
import { environment } from "src/environments/environment";
import { Observable, map, } from "rxjs";


@Injectable()
export class AppService {
    constructor(private httpClient: HttpClient) {}

    detectLogoForDomain(domain: string) {
        return this.httpClient.post(environment.STORES_LOG_LAMBDA_URL, {domain})
        .pipe(map((data: Data) => data['data']))
    }

    uploadImageAsPngToAws(base64Image: string, type: string) {
        return this.httpClient.put(environment.PLATFORM_UPLOAD_IMAGE_PNG_LAMBDA_URL,{
            type: type,
            imageData: base64Image
        }).pipe(map( (response: any) => response.data.imageUrl))
    }

    arrayBufferToBase64(buffer: ArrayBuffer): string {
        let binary = '';
        const bytes = new Uint8Array(buffer);
        const len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return btoa(binary);
    }

    proxyImage(imageUrl: string) {
        return this.httpClient.get(`${environment.IMAGE_PROXY_URL}/proxy?image_url=${encodeURIComponent(imageUrl)}`)
                .pipe(map((data: Data) => data['data']))
    }

    removeImage(imageUrl: string) {
        return this.httpClient.get(`${environment.IMAGE_PROXY_URL}/removed-proxy?image_url=${encodeURIComponent(imageUrl)}`)
                .pipe(map((data: Data) => data['data']))
    }

    readImage(target: any, validation: {allowedTypes?: string[], maxSize?: number, requestedFormat?: string}): Observable<{image: File, requestedFormat: string | ArrayBuffer| null} | null> {
        return new Observable((observer) => {
    
            const files = target.files;
            if (!files || files.length === 0) {
                observer.next(null);
                observer.complete();
                return;
            }
    
            const selectedImage = files[0];
            const reader = new FileReader();
            const fileType = selectedImage.type;
            const filesize = selectedImage.size;
            const maxSize = validation.maxSize || 1000000;
            const allowedTypes = validation.allowedTypes || ['image/png', 'image/jpeg', 'image/jpg'];
            const requestedFormat = validation.requestedFormat || 'arrayBuffer';
    
            if (!fileType) {
                observer.error({ 'message': 'Cannot process image' });
                return;
            }
            
            if (filesize > maxSize) {
                observer.error({ 'message': `Image should be less than ${maxSize / 1000000} MB` });
                return;
            }
    
            reader.onload = (ev: any) => {
                const fileReader = new FileReader();
    
                fileReader.onloadend = (e: any) => {
                    const arr = new Uint8Array(e.target.result);
                    let header = '';
                    for (let i = 0; i < 4; i++) {
                        header += arr[i].toString(16);
                    }
    
                    let type;
                    switch (header) {
                        case '89504e47':
                            type = 'image/png';
                            break;
                        case '47494638':
                            type = 'image/gif';
                            break;
                        case 'ffd8ffe0':
                        case 'ffd8ffe1':
                        case 'ffd8ffe2':
                        case 'ffd8ffe3':
                        case 'ffd8ffe8':
                            type = 'image/jpeg';
                            break;
                        default:
                            type = 'unknown';
                            break;
                    }
    
                    if (!allowedTypes.includes(type)) {                                
                        observer.error({ 'message': 'This image format is not valid' });
                        return;
                    }
                };
    
                fileReader.readAsArrayBuffer(selectedImage.slice(0, 4));
            };
    
            reader.onloadend = (e: any) => {
                observer.next({ image: selectedImage, requestedFormat: reader.result });
                observer.complete();
            };
    
            switch (requestedFormat) {
                case 'base64':
                    reader.readAsDataURL(selectedImage);
                    break;
                default:  // handles both 'arrayBuffer' and other unknown cases
                    reader.readAsArrayBuffer(selectedImage);
                    break;
            }
        });
    }
    
}