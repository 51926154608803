import { Injectable, OnDestroy } from '@angular/core';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { Observable, timer, Subject, EMPTY, of, throwError } from 'rxjs';
import { retryWhen, switchMap, delay, retry, catchError, tap } from 'rxjs/operators';
export const RECONNECT_INTERVAL = 1;


// @Injectable()
// export class WebSocketService implements OnDestroy {
//     connection$!: WebSocketSubject<any> | null;

//     private connections: Map<string, WebSocketSubject<any>> = new Map();

//     RETRY_SECONDS = 2;

//     connect(endpoint: string, params: any): Observable<any> {
//         let queryParams = this.serialize(params)
//         console.log(this.connection$)
//         return of(`${endpoint}?${queryParams}`).pipe(switchMap(wsUrl => {
//             if (this.connection$) {
//                 return this.connection$;
//             } else {
//                 this.connection$ = webSocket(wsUrl);
//                 console.log(this.connection$)

//                 return this.connection$;
//             }
//         }),
//             // retryWhen((errors) => errors.pipe(delay(this.RETRY_SECONDS)))
//             retry({
//                 count: Infinity, // or any specific number of retry attempts you want
//                 delay: (error, retryCount) => {
//                     console.log(`Attempt ${retryCount}: retrying in ${this.RETRY_SECONDS} seconds`);
//                     return timer(this.RETRY_SECONDS * 1000);
//                 }
//             }),
//             catchError(err => {
//                 // Handle the error or re-throw
//                 return throwError(() => err);
//             })
//         );
//     }

//     closeConnection() {
//         if (this.connection$) {
//             console.log('closeConnection');
//             this.connection$.complete();
//             this.connection$ = null;
//         }
//     }

//     ngOnDestroy() {
//         this.closeConnection();
//     }

//     serialize = function (obj: any) {
//         let str = [];
//         for (let p in obj)
//             str.push(`${p}=${obj[p]}`)
//         return str.join("&");
//     }
// }

@Injectable()
export class WebSocketService implements OnDestroy {
    private connections: Map<string, WebSocketSubject<any>> = new Map();
    private RETRY_SECONDS = 2; // Adjust retry seconds as needed

    constructor() {}

    ngOnDestroy(): void {
        this.closeAllConnections();
    }

    // Helper method to serialize params object to query string
    private serialize(obj: any): string {
        return Object.entries(obj).map(([key, val]) => {
            let value: string;
            // Check if val is of a type that can be directly converted to a string
            if (typeof val === 'string' || typeof val === 'number' || typeof val === 'boolean') {
              value = val.toString();
            } else {
              // Handle or throw an error for unsupported types
              // Or perhaps convert val to a string in some other way if appropriate
              throw new Error(`Unsupported type for query parameter value: ${typeof val}`);
            }
            return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
          }).join('&');
    }

    // Helper method to create a unique key for each WebSocket connection
    private createConnectionKey(endpoint: string, params: any): string {
        const serializedParams = this.serialize(params);
        return `${endpoint}?${serializedParams}`;
    }

    // Connects and returns an Observable for a WebSocket connection
    connect(endpoint: string, params: any): Observable<any> {
        const queryParams = this.serialize(params);
        const wsUrl = `${endpoint}?${queryParams}`;
        const key = wsUrl; // Your unique key logic here
    
        if (this.connections.has(key)) {
            return this.connections.get(key)!.asObservable();
        } else {
            const wsSubject = webSocket(wsUrl);
            this.connections.set(key, wsSubject);
    
            return wsSubject.pipe(
                retry({
                    count: Infinity, // retry indefinitely
                    delay: (error, retryCount) => {
                        console.log(`Attempt ${retryCount}: Retrying in ${this.RETRY_SECONDS} seconds`);
                        return timer(this.RETRY_SECONDS * 1000);
                    }
                }),
                catchError(err => {
                    console.error(`WebSocket connection failed: ${err.message}`);
                    return throwError(() => new Error(`WebSocket connection failed: ${err.message}`));
                })
            );
        }
    }

    // Closes a specific WebSocket connection using endpoint and params
    closeConnection(endpoint: string, params: any): void {
        const key = this.createConnectionKey(endpoint, params);
        this.closeConnectionByKey(key);
    }

    // Helper method to close connection by key
    private closeConnectionByKey(key: string): void {
        const connection = this.connections.get(key);
        if (connection) {
            connection.complete();
            this.connections.delete(key);
        }
    }

    // Closes all WebSocket connections
    private closeAllConnections(): void {
        this.connections.forEach((connection, key) => {
            connection.complete();
            this.connections.delete(key);
        });
    }
}
