
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { from, map } from 'rxjs';
import { CompanyService } from './company.service';

@Injectable()
export class VerifyCodeGuard {

    constructor( private router: Router) { }


    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        if(route.queryParams['code'] && (!localStorage.getItem('tempEmail') || !localStorage.getItem('tempPassword'))) {
            console.log('code found, but no email or password')
            this.router.navigate(['/login'])
            return false
        } else {
            console.log('code not found')
            return true
        }
    }
}
