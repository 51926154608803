import { ChangeDetectionStrategy, Component, signal, effect, computed } from '@angular/core';
import { AuthService } from '../core/services/auth.service';
import { NavigationEnd, Router } from '@angular/router';
import { CompanyService } from '../core/services/company.service';
import { MatDialog } from '@angular/material/dialog';
import { filter } from 'rxjs';
import { AnalyticsService } from '../core/services/analytics.service';

@Component({
  selector: 'app-header',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  public colorFill = signal(['white', 'white', 'white', 'white', 'white', 'white', 'white']);
  public selectedTab = signal(0);
  public company = this.companyService.company;

  constructor(
    private authService: AuthService,
    private router: Router,
    private companyService: CompanyService,
    private analyticsService: AnalyticsService,
    private dialog: MatDialog) { }

  ngOnInit() {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        this.analyticsService.page();
      });
  }

  signOut() {
    this.authService.signOut().subscribe({
      next: () => {
        this.companyService.resetCompanyId()
        this.companyService.companies.set(undefined);
        this.router.navigate(['/login']);
      }
    });
  }

  onOpenAdminCompaniesDialog() {
    this.authService.isAdmin().subscribe({
      next: async (isAdmin) => {
        if (isAdmin) {
          const { AdminCompaniesDialogComponent } = await import('../admin-companies-dialog/admin-companies-dialog.component');
          const dialogRef = this.dialog.open(AdminCompaniesDialogComponent, {
            width: '400px',
            height: '300px',
            disableClose: true,
          });

          dialogRef.afterClosed().subscribe(companyId => {
            console.log(`Dialog result: ${companyId}`);
            if (companyId != null) {
              this.companyService.companyId.set(companyId);
            }
          })
        }
      }
    });
  }


}
