import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable, map, of, switchMap } from 'rxjs';
import { VideosService } from '../core/services/videos-service';
import { CompanyService } from '../core/services/company.service';

@Injectable()
export class SimilarVideoResolverService {

    constructor(private videosService: VideosService, private companyService: CompanyService) {}

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        // Extract the query parameter of interest, for example, 'videoId'
        const videoId = route.queryParamMap.get('videoId');
    
        return of(videoId).pipe(
          switchMap(id => {
            if (id) {
              // If the 'videoId' query parameter exists, fetch similar videos
              return this.videosService.getVideoDetails(this.companyService.companyId()!, id);
            } else {
              // If the 'videoId' query parameter does not exist, you might want to resolve to a default value or navigate away
              // For example, resolve to an empty array or navigate back to a default route
              // this.router.navigate(['/default-route']);
              return of(null); // Resolving to an empty array or any default value you see fit
            }
          })
        );
      }
}
