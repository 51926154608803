import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CompanyService } from "./company.service";
import { Observable, delay, from, map, of } from "rxjs";
import { environment } from "src/environments/environment";
import { Data } from "@angular/router";
import { VideoAsset } from "src/app/shared/models/video.model";
import { VideoTemplate } from "src/app/create-video/template/template.interface";



@Injectable()
export class VideosService {

    constructor(
        private httpClient: HttpClient,
        private companyService: CompanyService,
        ) {
    }

    getPoPularVideoTemplates():Observable<VideoTemplate[]> {

        return this.httpClient.get<VideoTemplate[]>(`${environment.POPULAR_VIDEO_TEMPLATES_API}`).pipe(
            map((data: Data) => data['data']?.map( (item: any) => item.videoTemplate ))
        )
    }

    /**
     * Αν υπάρχουν company params τότε περνάμε το limit για να ξέρουμε πόσα βίντεο να επιστρέψουμε και 
     * σαν lastEvaluatedKey τον συνδυασμό videoId_timeCreated απο το τελευταίο βίντεο που εχει επιστραφεί
     * 
     * @param limit 
     * @param lastEvaluatedKey 
     * @returns 
     */
    getMyVideos(limit: number | null = null, lastEvaluatedKey: any | null = null): Observable<VideoAsset[]> {
        let companyId = this.companyService.companyId();
        let url = `${environment.VIDEO_API}/videos/${companyId}`;
        let params = new HttpParams();
        if (limit !== null) {
          params = params.set('limit', limit.toString());
        }
        if (lastEvaluatedKey !== null) {
          params = params.set('key', `${lastEvaluatedKey?.videoId}_${lastEvaluatedKey?.timeCreated}`);
        }
        return this.httpClient.get < any[] > (url, {params})
        .pipe(map((data: Data) => data['data']));   ;
    }

    downloadVideo(videoId: string) {
        let companyId = this.companyService.companyId();
        return this.httpClient.get(`${environment.VIDEO_API}/videolink/${companyId}/${videoId}`)
            .pipe(map((data: Data) => data['data']));
    }

    getRecentVideos() {
        return this.httpClient.get(`${environment.VIDEO_API}/videos/${this.companyService.companyId()}/recent`)
            .pipe(map((data: Data) => data['data']));
    }

    deleteAsset(videoId: string) {
        let companyId = this.companyService.companyId();
        return this.httpClient.delete(`${environment.VIDEO_API}/videos/${companyId}/${videoId}`);
    }

    getVideoDetails(companyId: string, videoId: string) {
        return this.httpClient.get(`${environment.VIDEO_API}/videos/${companyId}/${videoId}`)
            .pipe(map((data: Data) =>  data['data'] ));        
    }


    getRandomInt(min: number, max: number): number {
        min = Math.ceil(min); // Round up the minimum value
        max = Math.floor(max); // Round down the maximum value
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    exportVideoToFormat(companyId: string, assetId: string, format: string, resolution: string){
        return this.httpClient.get(`${environment.VIDEO_API}/videos/${companyId}/${assetId}/export?format=${format}&resolution=${resolution}`)
            .pipe(map((data: Data) => data['data']));
    }


    notifyForVideoDownload(body: any) {
        return this.httpClient.post(environment.VIDEO_NOTIFICATION_API, body);
    }

    renameVideo( videoId: string, name: string) {
        let companyId = this.companyService.companyId();
        const a = {
            videoId: videoId,
            name: name
        };
        return this.httpClient.put(`${environment.VIDEO_API}/videos/${companyId}`, a);
    }

    renderVideo(payload: any) {
        return this.httpClient.post(environment.VIDEO_RENDER_API, payload);
    }


    getTemplateLog() {
        return this.httpClient.get(`${environment.TEMPLATE_LOGS_API}/${this.companyService.companyId()}`);
    }

    
}
