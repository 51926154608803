import { HttpClient } from "@angular/common/http";
import { Injectable, Signal, computed, effect, signal } from "@angular/core";
import { Data } from "@angular/router";
import { Observable, map, of } from "rxjs";
import { environment } from "src/environments/environment";
import { AuthService } from "./auth.service";
import { LocalStorageService } from "./local-storage.service";

@Injectable()
export class CompanyService {

    companies = signal<any[] | undefined>( undefined );
    companyId = signal<string | undefined>( undefined );
    writAbleCompany = signal<any>({});
    // to company tha einai undefined mono an den exei ginei getCompanies
    // alliws tha einai null
    // etsi kseroume an exoume travhksei ta companies apo ton server
    company = computed(() => {
        if(this.companies() == undefined || this.companyId() == undefined) {
            return undefined
        }
        let company =  this.companies()?.filter( (company: any) => company.id == this.companyId())?.[0] 
        if(!company) {
            return null
        }
        if (company.current_period_end) {
            company.current_period_end = company.current_period_end * 1000;
        }
        return {...company, ...this.writAbleCompany()}
    });

    constructor(
        private http: HttpClient,
        private authService: AuthService,
        private localStorageService: LocalStorageService){
            effect(() => {
                if(this.authService.isLoggedIn()) {
                    this.getCompanies().subscribe()
                }
            }, {allowSignalWrites: true})

            effect(() => {
                if(this.companyId()) {
                    this.localStorageService.saveData('companyId', this.companyId())
                }
            })
            
    }

    resetCompanyId( ) {
        this.companyId.set(undefined)
        this.localStorageService.removeData('companyId');
    }

    getCompanies(): Observable<any> {
        return this.http.get(`${environment.COMPANY_API}`)
            .pipe(map((data: Data) => {
                this.companies.set(data['data'])  
                return data['data'];
            }));
    }

    // ελέγχει αν εχει pending update το company
    getCompanyPendingUpdateSinceTime(companyId: string, time: number): Observable<any> {
        return this.http.get(`${environment.COMPANY_API}/${companyId}`)
            .pipe(map((data: Data) =>  {
                let company = data['data']
                if(!company.latestSubscriptionUpdate) return true;
                return company.latestSubscriptionUpdate < time;
            }))
    }

    getLatestCompany(): Observable<string> {

        if(this.companyId() != undefined) {
            console.log('retrieved from service')
            return of(this.companyId()!);
        }
        if(this.localStorageService.getData('companyId') != undefined) {
            this.companyId.set(this.localStorageService.getData('companyId'))
            return of(this.companyId()!);
        }
        return this.http.get(`${environment.COMPANY_API}/first`)
            .pipe(map((data: Data) => {
                console.log('retrieved from api')
                this.companyId.set(data['data'])
                return data['data'];
            }));

    }

    createCompany(companyData: any) {
        return this.http.post(`${environment.COMPANY_API}`, companyData)
            .pipe(map((data: Data) => {
                this.companies.set([data['data']])
                this.companyId.set(data['data'].id)
            }));
    }

    updateCompany(companyId: string, companyData: any): Observable<any> {
        return this.http.put(`${environment.COMPANY_API}/${companyId}`, companyData)
            .pipe(map((data: Data) => data['data']));
    }

    userVisitsCompanyName() {
        return this.http.post(`${environment.USER_VISITS_COMPANY_NAME}`, {})
            .pipe(map((data: Data) => data['data']));
    }

    companyHasActiveSubscription(company: any): boolean {
        return company?.status == 'active' && company?.current_period_end > Date.now()
    }

    companyHasTrial(company: any): boolean {
        return company?.status == 'trialing' && company?.current_period_end > Date.now()
    }

    getVideosCount(companyId: string) {
        return this.http.get(`${environment.VIDEO_API}/videos/${companyId}/counts`);
    }

    saveCustomPalette(companyId: string, customPalette: any) {
        return this.http.put(`${environment.COMPANY_API}/${companyId}/custom_palettes`, {custom_palette: customPalette})
            .pipe(map((data: Data) => data['data']));
    }

    onboardingIsDone(companyId: string): Observable<any> {
        return this.http.put(`${environment.COMPANY_API}/${companyId}/onboarding`, {onboardingIsDone: true})
            .pipe(map((data: Data) => data['data']));
    }



}