import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { AuthService } from './services/auth.service';
import { AuthGuard } from './services/auth-guard.service';
import { AppService } from './services/app.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CompanyService } from './services/company.service';
import { AuthInterceptor } from './authentication-interceptor';
import { StripeService } from './stripe.service';
import { VideosService } from './services/videos-service';
import { LatestCompanyResolverService } from './services/latest-company-resolver.service';
import { LocalStorageService } from './services/local-storage.service';
import { AnalyticsService } from './services/analytics.service';
import { WebSocketService } from './services/websocket.service';
import { RegistrationGuard } from './services/registration-guard.service';
import { VerifyCodeGuard } from './services/verify-code-guard.service';
import { SimilarVideoResolverService } from '../create-video/similar-video-resolver.service';

@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    
  ],
  providers: [
    AuthService,
    AuthGuard,
    VerifyCodeGuard,
    RegistrationGuard,
    AppService,
    CompanyService,
    StripeService,
    VideosService,
    LocalStorageService,
    AnalyticsService,
    CurrencyPipe,
    LatestCompanyResolverService,
    SimilarVideoResolverService,
    WebSocketService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ],
  exports: [
    HttpClientModule
  ]
})
export class CoreModule { }
