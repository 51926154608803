import { ChangeDetectionStrategy, Component, signal } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LayoutComponent {

  public showHeader = signal(true);

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {
    // effect(() => console.log('1The count is: ' + this.count()));
    // effect(() => console.log('2The count is: ' + this.count()));
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Check if the current route is the login route
        this.showHeader.set(this.activatedRoute.firstChild?.routeConfig?.path === 'login' || 
        this.activatedRoute.firstChild?.routeConfig?.path === 'register' ||
        this.activatedRoute.firstChild?.routeConfig?.path === 'forgot-password' ||
        this.activatedRoute.firstChild?.routeConfig?.path === 'subscribe' );
      }
    });
  }
}
