
import { Router, NavigationEnd } from '@angular/router';
import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DOCUMENT } from '@angular/common';
import { LocalStorageService } from './local-storage.service'; 
import { v4 as uuid } from 'uuid'; 
import { CookieService } from "ngx-cookie-service";
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';


const LOCAL_STORAGE_COMPANY_ID = 'ajs_user_id';
const LOCAL_STORAGE_COGNITO_USER_ID = 'user_id';
const LOCAL_STORAGE_ID_TOKEN = 'id_token';
const LOCAL_STORAGE_ANONYMOUS_ID = 'ajs_anonymous_id'
const LOCAL_STORAGE_COMPANY_TRAITS = 'ajs_user_traits'

declare var gtag: Function;

@Injectable()
export class AnalyticsService {

    constructor(
        private router: Router,
        private authService: AuthService,
        private httpClient: HttpClient,
        @Inject(DOCUMENT) private document: Document,
        private localStorageService: LocalStorageService,
        private cookieService: CookieService
    ) {
        //Αν δεν υπάρχει anonymousId το settaroume
        if (!this.localStorageService.getData(LOCAL_STORAGE_ANONYMOUS_ID)) {
            this.localStorageService.saveData(LOCAL_STORAGE_ANONYMOUS_ID, uuid());
        }  
    }

    public event(eventName: string, params: {}) {
        if (!environment.production) {
            return;
        }
        // gtag('event', eventName, params);
    }

    public init() {

        if (!environment.production) {
            return;
        }
        try {
            const script1 = document.createElement('script');
            script1.async = true;
            script1.src = 'https://www.googletagmanager.com/gtag/js?id=' + environment.googleAnalyticsKey;
            document.head.appendChild(script1);

            const script2 = document.createElement('script');
            script2.innerHTML = `
				window.dataLayer = window.dataLayer || [];
				function gtag(){dataLayer.push(arguments);}
				gtag('js', new Date());
			`;
            document.head.appendChild(script2);
            this.listenForRouteChanges();
        } catch (ex) {
            console.error('Error appending google analytics');
            console.error(ex);
        }
        // setTimeout(() => {
        this.authService.getUserEmail().subscribe({
            next: (email: string | null) => {
                console.log(email);
                // edw den prepei na to valw mono sto production kai oxi sto localhost?
                try {
                    const script3 = document.createElement('script');
                    script3.innerHTML = `gtag('set', {'user_id': '` + email + `'}); // Set the user ID using signed-in user_id.`;
                    document.head.appendChild(script3);
                } catch (ex) {
                    console.error('Error appending google analytics');
                    console.error(ex);
                }
            },
            error: (err:any) => {
                console.log(err);
            }
        })

    }

    private listenForRouteChanges() {
        this.router.events.subscribe( {
            next: (event: any) => {
                if (event instanceof NavigationEnd) {
                    gtag('config', environment.googleAnalyticsKey, {
                        'page_path': event.urlAfterRedirects,
                    });
                    // console.log('Sending Google Analytics hit for route', event.urlAfterRedirects);
                    // console.log('Property ID', environment.googleAnalyticsKey);
                }
            }
        });
    }

    track(event: any, properties = {}, context: any = {}, options = null): void {

        context['anonymousId'] =  this.localStorageService.getData(LOCAL_STORAGE_ANONYMOUS_ID)
        const savedUserId = this.localStorageService.getData(LOCAL_STORAGE_COMPANY_ID)
        if(savedUserId){
            context['userId'] = savedUserId
        }
        const serverSideContext = this.constructContext()
        let sendData = {
            event: event, 
            properties: properties,
            context: { ...serverSideContext, ...context },
            options: options
        }
        // To segment χρησιμοποιεί τα cached traits μόνο για τα identify calls.
        // Ωστόσο περνόντας τα στο track μπορούμε να εχουμε χρησιμες πληροφορίες όπως το user email
        // το οποίο θα στείλουμε στο facebook conversions api
        const savedTraits = this.localStorageService.getData(LOCAL_STORAGE_COMPANY_TRAITS)
        if (savedTraits) {
            sendData.context['traits'] = { ...savedTraits, ...sendData.context['traits'] }
        }
        
        this.httpClient.post(`${environment.CUSTOMER_ANALYTICS_API}/track`, sendData).subscribe()
    }

    /**
     * Identify. Πως λειτουργεί στο segment
     * Όταν κανουμε ενα identify call κοιτάμε πρώτα αν εχουμε αποθηκευμένο user_id και traits
     * Υπάρχουν 2 βασικες περιπτώσεις εφόσον ειχαμε user_id αποθηκευμένο.
     * 1) Αν εχουμε known_company_id και το known_company_id είναι ίδιο με το companyId που χρησιμοποιούμε στο identify call
     * τότε θα πρεπει να παρουμε τα σωσμένα traits και να τα προσθεσουμε στα traits που περνάμε τώρα.
     * 2) Αν εχουμε known_company_id και ειναι διαφορετικό απο το companyId  τότε θα πρεπει να δημιουργήσουμε ενα καινουργιο anonymousId
     * */
    identify(companyId: string, traits = {}, context:any = {}, options = null): void {
        const known_company_id = this.localStorageService.getData(LOCAL_STORAGE_COMPANY_ID)
        if(known_company_id) {
            let saved_traits = this.localStorageService.getData(LOCAL_STORAGE_COMPANY_TRAITS)
            if(!saved_traits) {
                saved_traits = {} as Object
            }
            // τα βαζουμε με αυτη τη σειρά γιατί τα καιvουργια traits πρεπει να κανουν override τα παλια
            if(known_company_id == companyId) {
                traits = { ...saved_traits, ...traits }
            } else {
                this.localStorageService.saveData(LOCAL_STORAGE_ANONYMOUS_ID, uuid())
            }
        }

        context['anonymousId'] =  this.localStorageService.getData(LOCAL_STORAGE_ANONYMOUS_ID)

        const serverSideContext = this.constructContext()
        let sendData = {
            userId: companyId, 
            traits: traits,
            context: { ...serverSideContext, ...context },
            options: options
        }
        this.localStorageService.saveData(LOCAL_STORAGE_COMPANY_ID, companyId)
        this.localStorageService.saveData(LOCAL_STORAGE_COMPANY_TRAITS, traits)

        this.httpClient.post(`${environment.CUSTOMER_ANALYTICS_API}/identify`, sendData).subscribe()
    }

    page(context: any = {}, options = null): void {

        const serverSideContext = this.constructContext()
        const properties = serverSideContext.page
        let sendData: any = {
            properties: properties,
            context: { ...serverSideContext, ...context },
            options: options
        }
        const user_id = this.localStorageService.getData(LOCAL_STORAGE_COMPANY_ID)
        if(user_id) {
            sendData.context['userId'] = user_id
        }
        const anonymous_id = this.localStorageService.getData(LOCAL_STORAGE_ANONYMOUS_ID)
        if(anonymous_id) {
            sendData.context['anonymousId'] = anonymous_id
        }

        this.httpClient.post(`${environment.CUSTOMER_ANALYTICS_API}/page`, sendData).subscribe()
    }

    private constructContext(): {page: any; userAgent: string} {
        const fbp = this.cookieService.get('_fbp')
        const fbc = this.cookieService.get('_fbc')

        // console.log(fbp, fbc)

        let context: any = {
            page: {
                "path": window.location.pathname,
                "referrer": this.document.referrer,
                "search": this.document.location.search,
                "title": this.document.title,
                "url": window.location.href
            },
            userAgent: window.navigator.userAgent
        }   

        if(fbp){
            context['fbp']  = fbp
        }
        if(fbc){
            context['fbc']  = fbc
        }

        return context
    }

    // kanoume clear ton user kai ta traits kai dhmiourgoyme kaniourgio anonymousId
    reset() {
        this.localStorageService.removeData(LOCAL_STORAGE_ANONYMOUS_ID);
        this.localStorageService.removeData(LOCAL_STORAGE_COMPANY_ID);
        this.localStorageService.removeData(LOCAL_STORAGE_COMPANY_TRAITS);
        this.localStorageService.removeData(LOCAL_STORAGE_COGNITO_USER_ID);
        this.localStorageService.removeData(LOCAL_STORAGE_ID_TOKEN);
        this.localStorageService.saveData(LOCAL_STORAGE_ANONYMOUS_ID, uuid());
    }
    
}