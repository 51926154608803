import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable, Injector } from "@angular/core";
import { EMPTY, Observable, catchError, of, switchMap, throwError } from "rxjs";
import { AuthService } from "./services/auth.service";
import { environment } from "src/environments/environment";
import { Router } from "@angular/router";
import { LocalStorageService } from "./services/local-storage.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(
        private injector: Injector,
        private router: Router
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (request.url.startsWith('../assets') || request.url.startsWith('assets') || request.url.startsWith('/assets') || request.url.includes('.s3.') ||
            this.shouldUrlBeExcluded(request.url)) {
            return next.handle(request);
        }

        const localStorageService = this.injector.get(LocalStorageService);
        const id_token = localStorageService.getData('id_token');
        let apiReq = this.setIdToken(request, id_token);
        return next.handle(apiReq)
            .pipe(catchError(err => {
                if (err.status === 401 || err.status === 0) {
                    console.log('status error ' + err.status);
                    const authService = this.injector.get(AuthService);
                    // If login session exists and refresh token succeeds retry the request
                    return authService.getCurrentSession(true).pipe(
                        switchMap(session => {
                            // console.log('session', session);
                            if (!session.tokens) {
                                authService.signOut();
                                setTimeout(() => {
                                    // reload the page
                                    location.reload();
                                }, 500);
                                // Return an empty observable to satisfy the return type
                                return EMPTY;
                            } else {
                                let apiReq = this.setIdToken(request, session.tokens?.idToken?.toString());
                                if (session.tokens?.idToken) {
                                    localStorageService.saveData('id_token', session.tokens?.idToken?.toString());
                                }
                                return next.handle(apiReq)
                            }

                        }));
                } else {
                    return throwError(() => err);
                }
            }));
    }

    private setIdToken(request: any, token: any) {
        return request.clone({
            setHeaders: {
                Authorization: `Bearer ${token}`
            }
        });
    }

    public shouldUrlBeExcluded(targetUrl: string) {

        const excludedUrls: any[] = [
            `${environment.CUSTOMER_ANALYTICS_API}/track`,
            // `${environment.PLATFORM_PRESIGNED_URL_API}/presigned_url`,
            `${environment.REGISTER_API}/register`,
            // environment.CUSTOMER_CAMPAIGN_MESSAGE_API
        ];

        return excludedUrls.includes(targetUrl)
    }
}
