
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { from, map } from 'rxjs';
import { CompanyService } from './company.service';

@Injectable()
export class RegistrationGuard {

    constructor(private authService: AuthService, private companyService: CompanyService, private router: Router) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        console.log(this.authService.isLoggedIn())

        if(this.authService.isLoggedIn() == false) {
            return true
        } else {
            return from(this.companyService.getLatestCompany()).pipe(map((latestCompanyId: string | null) => {
                return true
            }))
        }
         /**
         * αν ειναι πρωτη φορά που τρέχει το signal τότε θα είναι undefined
         * Οπότε πρεπει να δουμε αν ο χρηστης ειναι loggarismenos απο το localStorage
         * Αν είναι loggarismenos πρέπει να δουμε company .
         * Αν εχει τότε δεν μπορεί να παει σε αυτό το route
         **/ 
    }
}
