import { Injectable, Injector, effect, signal } from '@angular/core';
import { Observable, catchError, forkJoin, from, map, of } from 'rxjs';

import { Amplify } from 'aws-amplify';
import { signIn, confirmSignUp, signOut, resendSignUpCode, fetchAuthSession, getCurrentUser, resetPassword, confirmResetPassword, updatePassword, fetchUserAttributes } from 'aws-amplify/auth';
import { Hub } from 'aws-amplify/utils';

import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Data } from '@angular/router';
import { LocalStorageService } from './local-storage.service';


export interface IUser {
    email: string;
    password: string;
    showPassword: boolean;
    code: string;
    name: string;
}

const ADMIN = 'admin';
@Injectable()
export class AuthService {

    public isLoggedIn: any = signal<boolean>(false);
    public userRefresh = signal<number>(1);
    public user: any = signal<any>(null);


    constructor(private httpClient: HttpClient, private injector: Injector,) {
        const localStorageService = this.injector.get(LocalStorageService);
        Amplify.configure({
            Auth: {
                Cognito: environment.POOL_DATA
            }
        });
        
        if (localStorageService.getData('user_id')) {
            this.isLoggedIn.set(true);
        }

    }

    public signIn(user: IUser): Observable<any> {
        return from(signIn({ username: user.email, password: user.password }));
    }

    public confirmSignUp(user: IUser): Observable<any> {
        return from(confirmSignUp({ username: user.email, confirmationCode: user.code }));
    }

    public signUp(credentials: {
        'email': string;
        'email_confirmation': string;
        'password': string;
        'password_confirmation': string;
        'lang': any;
        'TOS': any;
    }) {
        return this.httpClient.post<any>(`${environment.REGISTER_API}/register`, credentials);
    }

    public signOut(): Observable<any> {
        return from(signOut()
            .then(() => this.isLoggedIn.set(false)));
    }

    confirmPassword(email: string, code: string, new_password: string) {
        return from(confirmResetPassword({ username: email, confirmationCode: code, newPassword: new_password }));
    }

    public resendConfirmationCode(username: string): Observable<any> {
        return from(resendSignUpCode({ username: username }))
    }

    public getAccountInformation() {
        return this.httpClient.get(environment.ACCOUNT_INFORMATION_API)
            .pipe(map((data: Data) => data['data']));
    }

    public getUserAccountInfo(): Observable<any> {
        const userData$ = from(fetchUserAttributes()).pipe(
            catchError(error => {
                console.error('Error getting user data', error);
                return of(null); // Return null in an Observable if an error occurs
            })
        );

        const accountInfo$ = this.httpClient.get(environment.ACCOUNT_INFORMATION_API)
            .pipe(map((data: any) => data['data']));

        return forkJoin({cognito: userData$, dynamodb: accountInfo$});
    }

    public getCurrentSession(forceRefresh: boolean): Observable<any> {
        return from(fetchAuthSession({ forceRefresh: forceRefresh }))
    }


    isLoggedInUser() {
        return getCurrentUser()
            .then((user: any) => {
                // console.log('isLoggedInUser', user);
                // console.log('this.user()', this.user());
                // console.log('this.isLoggedIn()', this.isLoggedIn());
                if (user) {
                    this.isLoggedIn.set(true);
                    console.log('this.isLoggedIn()', this.isLoggedIn());
                    return true;
                } else {
                    this.isLoggedIn.set(false);
                    console.log('this.isLoggedIn()', this.isLoggedIn());
                    return false;
                }
            })
            .catch((e) => {
                console.log('error', e)
                return false;
            });
    }

    public forgotPassword(username: string): Observable<any> {
        return from(resetPassword({ username: username }))
    }

    public forgotPasswordSubmit(data: { username: string; code: string; newPassword: string }): Observable<any> {
        const { username, code, newPassword } = data
        return from(confirmResetPassword({ username: username, confirmationCode: code, newPassword: newPassword }))
    }

    public changePassword(data: { oldPassword: string; newPassword: string }): Observable<any> {
        const { oldPassword, newPassword } = data
        // return from(Auth.currentAuthenticatedUser()
        // .then((user: any) => {
        return from(updatePassword({ oldPassword, newPassword }));
        // }));
    }


    public setBillingInformation(data: any) {
        const sendData = { billingAddress: data };
        return this.httpClient.put(`${environment.STRIPE_API}/billing-information`, sendData);
    }

    public removeBillingInformation() {
        const sendData = { billingAddress: null };
        return this.httpClient.put(`${environment.STRIPE_API}/billing-information`, sendData);
    }

    public getUserEmail(): Observable<string | null> {

        // if (this.user() !== null) {
        //     // return of(this.user()!.email);
        //     return this.user()!.pipe(
        //         map(user => user.email),
        //         catchError(() => of(null))
        //     );
        // }
        return from(fetchUserAttributes()).pipe(
            // Map the user to their email attribute
            map(user => user.email!),
            // Catch any errors and return null or an appropriate error message
            catchError(error => {
                console.error('Error getting user email', error);
                return [null];
            })
        );
    }

    public hasRoles(roles: any[]): Observable<boolean> {
        return from(fetchUserAttributes()).pipe(
            map(user => {
                const userRole = user['custom:role'];
                return roles.includes(userRole);
            }),
            catchError(error => {
                console.error('Error fetching user role', error);
                return [false];
            })
        );
    }

    public isAdmin(): Observable<boolean> {
        return this.hasRoles([ADMIN]);
    }

}
