import { Data } from '@angular/router';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import {Injectable, WritableSignal, signal } from '@angular/core';
import { loadStripe } from '@stripe/stripe-js';
import { from } from 'rxjs';


@Injectable()
export class StripeService{

    public products:any =  signal([])

    public paymentItem: any = signal( null
        // {
        //     product: {
        //         clientSecret: 'pi_3NvdBICghCvUe1GD0nVCqzIT_secret_ppsFDSe5zf0OUq21FmdXOwXja',
        //         subscriptionId: "sub_1NvdWfCghCvUe1GDZHiLUDDG",
        //         name: "Basic plan",
        //         price: 10,
        //         productId: "prod_KQnwCdEcfYybOR",
        //         duration: "month",
        //         currency: "eur",
        //         selectedPrice: {
        //             id: "price_1JlwKxCghCvUe1GD0qGLbvAm"
        //         }
        //     }
        // }
    )

    constructor(private http: HttpClient) {
        this.getSubscriptionProducts().subscribe((products: any) => {
            // console.log('products', products);
            this.products.set(products)
        });
    }

    // checked
    getSubscriptionProducts() {
        return this.http.get(`${environment.STRIPE_API}/subscription_products`)
            .pipe(map( (data: Data) => data['data']?.sort((a: any, b: any) => parseInt(a.metadata.index) - parseInt(b.metadata.index))));
    }

    checkVat(sendData: { countryCode: string; vat: string; }) {
        return this.http.post(`${environment.STRIPE_API}/vies`, sendData)
            .pipe(map( (data: Data) => data['data']));
    }

    loadStripeJs() {
        return from(loadStripe(environment.STRIPE_PUBLIC_KEY))
    }

    // μονο αυτο θελει ενα τσεκαρισμα παλι
    getPaymentIntentSecret(sendData:any) {
        return this.http.post(`${environment.STRIPE_API}/payment-intent-secret`, sendData);
    }

    cancelSubscription(sendData:any) {
        return this.http.post(`${environment.STRIPE_API}/subscription_cancel`, sendData );
    }

    releaseSubscriptionSchedule(sendData: any) {
        return this.http.post(`${environment.STRIPE_API}/subscription_schedule_release`, sendData );
    }

    reactivateSubscription(sendData: any) {
        return this.http.post(`${environment.STRIPE_API}/reactivate-subscription`, sendData );
    }

    changePaymentMethodForSubscription(sendData: any) {
        return this.http.put(`${environment.STRIPE_API}/subscription_payment_method`, sendData );
    }

    // checked
    createSubscription(sendData: any) {
        return this.http.post(`${environment.STRIPE_API}/create-subscription`, sendData)
            .pipe(map( (data: Data) => data['data']));
    }

    updateSubscription(sendData: any) {
        return this.http.post(`${environment.STRIPE_API}/update-subscription`, sendData);
    }

    verifyPromotionCode(sendData: any) {
        return this.http.post(`${environment.STRIPE_API}/promotion_code/verify`, sendData)
            .pipe(map( (data: Data) => data['data']));
    }

    getCoupon(codeName: string) {
        return this.http.get(`${environment.STRIPE_API}/promotion_code/${codeName}`)
            .pipe(map( (data: Data) => data['data']));
    }

    countyHasEuVat(countryName: string) {
        let countries = [
            ['Australia', 'au_abn'],
            ['Austria', 'eu_vat'],
            ['Belgium', 'eu_vat'],
            ['Brazil', 'br_cnpj'],
            ['Brazil', 'br_cpf'],
            ['Bulgaria', 'eu_vat'],
            ['Canada', 'ca_bn'],
            ['Canada', 'ca_gst_hst'],
            ['Canada', 'ca_pst_bc'],
            ['Canada', 'ca_pst_mb'],
            ['Canada', 'ca_pst_sk'],
            ['Canada', 'ca_qst'],
            ['Chile', 'cl_tin'],
            ['Croatia', 'eu_vat'],
            ['Cyprus', 'eu_vat'],
            ['Czech Republic', 'eu_vat'],
            ['Denmark', 'eu_vat'],
            ['Estonia', 'eu_vat'],
            ['Finland', 'eu_vat'],
            ['France', 'eu_vat'],
            ['Germany', 'eu_vat'],
            ['Greece', 'eu_vat'],
            ['Hong Kong', 'hk_br'],
            ['Hungary', 'eu_vat'],
            ['India', 'in_gst'],
            ['Indonesia', 'id_npwp'],
            ['Ireland', 'eu_vat'],
            ['Israel', 'il_vat'],
            ['Italy', 'eu_vat'],
            ['Japan', 'jp_cn'],
            ['Japan', 'jp_rn'],
            ['Latvia', 'eu_vat'],
            ['Liechtenstein', 'li_uid'],
            ['Lithuania', 'eu_vat'],
            ['Luxembourg', 'eu_vat'],
            ['Malaysia', 'my_frp'],
            ['Malaysia', 'my_itn'],
            ['Malaysia', 'my_sst'],
            ['Malta', 'eu_vat'],
            ['Mexico', 'mx_rfc'],
            ['Netherlands', 'eu_vat'],
            ['New Zealand', 'nz_gst'],
            ['Norway', 'no_vat'],
            ['Poland', 'eu_vat'],
            ['Portugal', 'eu_vat'],
            ['Romania', 'eu_vat'],
            ['Russian Federation', 'ru_inn'],
            ['Russian Federation', 'ru_kpp'],
            ['Saudi Arabia', 'sa_vat'],
            ['Singapore', 'sg_gst'],
            ['Singapore', 'sg_uen'],
            ['Slovakia', 'eu_vat'],
            ['Slovenia', 'eu_vat'],
            ['South Africa', 'za_vat'],
            ['Spain', 'es_cif'],
            ['Spain', 'eu_vat'],
            ['Sweden', 'eu_vat'],
            ['Switzerland', 'ch_vat'],
            ['Taiwan', 'tw_vat'],
            ['Thailand', 'th_vat'],
            ['United Arab Emirates', 'ae_trn'],
            ['United Kingdom', 'gb_vat'],
            ['United Kingdom', 'eu_vat'],
            ['United States', 'us_ein'],
        ];

        const country = countries.filter(c => c[0] === countryName);
        if(!country[0]) return false;
        return country[0]?.[1] == 'eu_vat';
    }
}

