export const environment = {
    production: true,
    POOL_DATA: {
        userPoolId : 'eu-west-1_zxQjeVrn5',
        userPoolClientId : '2nam03oiruaqt483trd2d71jkq'
    },
    STORES_LOG_LAMBDA_URL: 'https://y6lbhawsglxbz32cfqo3ngwsi40zcoof.lambda-url.eu-west-1.on.aws/',
    PLATFORM_UPLOAD_IMAGE_PNG_LAMBDA_URL: 'https://ycsfzgqwdjhfgmizyxxbd7el7i0jihoe.lambda-url.eu-west-1.on.aws/',
    COMPANY_API: 'https://nyclqmvzzd.execute-api.eu-west-1.amazonaws.com/prod/companies',
    USER_VISITS_COMPANY_NAME: 'https://gywjloe3u3.execute-api.eu-west-1.amazonaws.com/prod/companies/latest/track',
    STRIPE_API: 'https://qs0864pqh6.execute-api.eu-west-1.amazonaws.com/prod',
    STRIPE_PUBLIC_KEY: 'pk_live_51HR0glCghCvUe1GDQM3M3KGBjOxKmxt67q2dRCWMVadlrlfcML8IdciRBSu4nrGDJ24liPi6g1tc2Vn0KO3zEW6d00w1gXEDEP',
    REGISTER_API: 'https://b9v0xo4slc.execute-api.eu-west-1.amazonaws.com/prod',

    ACCOUNT_INFORMATION_API: 'https://egx1uzuy4i.execute-api.eu-west-1.amazonaws.com/prod/account-information',
    VIDEO_API: 'https://ifyzgylt1f.execute-api.eu-west-1.amazonaws.com/prod',
    identities: {
        accessAmazonServices: 'eu-west-1:6cbe0a71-e450-40ad-a67d-36f0a2f1b611',
    },
    SCRAPE_LAMBDA: 'platform-scrape-urls-prod-detectHeaders',
    IMAGE_PROXY_URL: 'https://g9gelh5hkc.execute-api.eu-west-1.amazonaws.com/prod',
    VIDEO_NOTIFICATION_API: 'https://tijbiv3j3g.execute-api.eu-west-1.amazonaws.com/prod/notify-download',

    CUSTOMER_ANALYTICS_API: 'https://4pguub7bl9.execute-api.eu-west-1.amazonaws.com/prod/platform',
    googleAnalyticsKey: '',
    FB_APP_ID: '1910404349015349',
    VIDEO_WEBSOCKET_URL: 'wss://5zwb88u42m.execute-api.eu-west-1.amazonaws.com/prod',
    VIDEO_RENDER_API: 'https://ggyp7k2d5a.execute-api.eu-west-1.amazonaws.com/prod/videos/render',
    TEMPLATE_LOGS_API: 'https://h97s0ptwz5.execute-api.eu-west-1.amazonaws.com/prod/template_logs',
    VIDEO_TEMPLATES_API: 'https://lgtlxigsa8.execute-api.eu-west-1.amazonaws.com/prod',
    POPULAR_VIDEO_TEMPLATES_API: 'https://pkjd9u3ghi.execute-api.eu-west-1.amazonaws.com/prod/popular-templates',
    GPT_API: "https://a1yapuacl3.execute-api.eu-west-1.amazonaws.com/prod",
    SCHEDULE_POSTS: "",
    SENTRY_ENVIRONMENT: "production",
};
