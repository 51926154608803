import { ChangeDetectionStrategy, Component, computed, effect, signal } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { CompanyService } from './core/services/company.service';
import { AuthService } from './core/services/auth.service';
import { AnalyticsService } from './core/services/analytics.service';
import { environment } from 'src/environments/environment';
import { Hub } from 'aws-amplify/utils';
import { LocalStorageService } from './core/services/local-storage.service';
import * as Sentry from "@sentry/angular-ivy";

@Component({
  selector: 'app-root',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public title = 'Ecommerce Video Platform - Gaming Brotherhood';
  isUnsupportedScreen: boolean = false;
  constructor(private router: Router, private companyService: CompanyService, private authService: AuthService, private analyticsService: AnalyticsService, private localStorageService: LocalStorageService) {

    Hub.listen('auth', ({ payload }) => {
      console.log(1, payload);
      switch (payload.event) {
        case 'signedIn':
          console.log('user have been signedIn successfully.');
          this.authService.getCurrentSession(false).subscribe({
            next: (session) => {
              if (session.tokens?.idToken) {
                this.localStorageService.saveData('id_token', session.tokens?.idToken?.toString());
              }
              this.localStorageService.saveData('user_id', session.userSub);
              // console.log('user ', this.authService.user());
              this.authService.isLoggedIn.set(true);
              this.router.navigate(['./']);
            }
          });
          break;
        case 'signedOut':
          console.log('user have been signedOut successfully.');
          this.authService.isLoggedIn.set(false);
          this.analyticsService.reset();
          break;
        case 'tokenRefresh':
          console.log('auth tokens have been refreshed.');
          break;
        case 'tokenRefresh_failure':
          console.log('failure while refreshing auth tokens.');
          break;
        case 'signInWithRedirect':
          console.log('signInWithRedirect API has successfully been resolved.');
          break;
        case 'signInWithRedirect_failure':
          console.log('failure while trying to resolve signInWithRedirect API.');
          break;
      }
    });

    effect(() => {
      if (this.authService.isLoggedIn()) {
        this.authService.getUserAccountInfo().subscribe({
          next: (user) => {
            // console.log('user: ', user);
            // console.log('this.companyService.company', this.companyService.company());
            this.authService.user.set(user);
            if (user.cognito.email) {
              // console.log('Sentry.setUser', user.cognito.email);
              Sentry.setUser({ email: user.cognito.email });
              this.companyService.userVisitsCompanyName()
                .subscribe();
            }
            // kane identify μόνο όταν δεν ειναι admin
            if (this.companyService.companyId()) {
              this.authService.isAdmin().subscribe({
                next: (isAdmin) => {
                  if(!isAdmin) {
                    this.analyticsService.identify(this.companyService.companyId()!, {
                      email: environment.production ? user.cognito.email : user.cognito.email + '----dev',
                      env: environment.production ? 'prod' : 'dev'
                    })
                  }  
                }
              });
            }

          },
          error: (err) => {
            // edw mipws prepei na kanw logout/reset?
            console.log('err666', err);
          }
        });
      }
    }, { allowSignalWrites: true })

  }

  // ngOnInit(): void {
  //   // this.isUnsupportedScreen = window.innerHeight < 800;
  //   this.isUnsupportedScreen = screen.height < 900;
  //   console.log(window.innerHeight, screen.height);
  // }
}
