import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { AuthGuard } from './core/services/auth-guard.service';
import { LatestCompanyResolverService } from './core/services/latest-company-resolver.service';
import { LayoutComponent } from './layout/layout.component';
import { RegistrationGuard } from './core/services/registration-guard.service';
import { VerifyCodeGuard } from './core/services/verify-code-guard.service';
import { SimilarVideoResolverService } from './create-video/similar-video-resolver.service';
const routes: Routes = [
  { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },
  { path: 'forgot-password',  loadChildren: () => import('./forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule) },
  { path: 'link-forgot',  loadChildren: () => import('./link-forgot-password/link-forgot-password.module').then(m => m.LinkForgotPasswordModule) },
  { path: 'register', canActivate: [VerifyCodeGuard, RegistrationGuard], loadChildren: () => import('./sign-up/sign-up.module').then(m => m.SignUpModule) },
  
  {
    path: '',
    component: LayoutComponent,
    resolve: {
      latest_company: LatestCompanyResolverService,
    },
    canActivate: [AuthGuard],
    children: [
     
      { path: 'faq', loadChildren: () => import('./faq/faq.module').then(m => m.FaqModule) },
      { path: 'my-videos',canActivate: [AuthGuard],loadChildren: () => import('./my-videos/my-videos.module').then(m => m.MyVideosModule)},
      { path: '',loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)},
      { path: 'create-video', resolve: {similarVideo: SimilarVideoResolverService},  loadChildren: () => import('./create-video/create-video.module').then(m => m.CreateVideoModule) },
      { path: 'subscribe', loadChildren: () => import('./subscription/subscription.module').then(m => m.SubscriptionModule) },
      { path: 'settings',  loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule) },

    ]
  },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule, MatIconModule, ScrollingModule]
})
export class AppRoutingModule { }
